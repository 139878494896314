import React from 'react'
import { NavLink } from 'react-router-dom'

import SideMenuItems from '../SideMenuItem/SideMenuItems'
import { scroller } from 'react-scroll'

import './Header.scss'

export default function Header() {
  return (
	<header className='header header--hidden'>
		<div className='header__conteiner'>
		<nav className='header__menu'>
			<ul className='header__menu-list'>
				<li  className='header__list-item'><div data-id = {13} onClick={(e) => {
					scroller.scrollTo(SideMenuItems[13].text_id, { smooth: true })
					document.querySelector(".side__menu-item--active")?.classList?.remove('side__menu-item--active')
					let newActiveIndex = parseInt(e.target.getAttribute("data-id"))
					document.getElementsByClassName("side__menu-item")[parseInt((newActiveIndex))]?.classList.add("side__menu-item--active")
					}}>Contact Us</div></li>
				<li className='header__list-item'><NavLink className='header__menu-link' to={`/demos/`} target="_blank">Demos</NavLink></li>
				<li className='header__list-item'><NavLink className='header__menu-link' to={`/buy/`} target="_blank">Buy</NavLink></li>
				<li className='header__list-item'><NavLink className='header__menu-link' to={`/docs/`} target="_blank">Docs</NavLink></li>
			</ul>
		</nav>
		</div>
	</header>
  )
}
