import YoutubeButton from "../../Components/YoutubeButton/YoutubeButton"

import placeholder from "../../videos/Completevideo With Voiceover-1.mp4"

import oracleRegisterCDB from "../../videos/oracle/Register_an_Oracle_container_database.mp4"
import createPDB from "../../videos/oracle/Add_an_Oracle_pluggable_database.mp4"

//import pdbSettings from "../../videos/oracle/"
//import dropPDB from "../../videos/oracle/"
import openAndClosePDB from "../../videos/oracle/Open_and_Close_Oracle_pluggable_databases.mp4"
import duplicate from "../../videos/oracle/Duplicate_Oracle_standalones.mp4"
import backup from "../../videos/oracle/Backup_an_Oracle_pluggable_db.mp4"
import restore from "../../videos/oracle/Restore Oracle_pluggable_database_from_Export.mp4"
import copyPDB from "../../videos/oracle/Copy Oracle pluggable database.mp4"

//import createRestorePoint from ""
import flashbackRestorePoint from "../../videos/oracle/Create_a_flashback_point_for_an_Oracle_pluggable_database.mp4"
import deleteRestorePoints from "../../videos/oracle/My Project Delete Oracle Restore point.mp4"
import exportASchema from "../../videos/oracle/Export_an_Oracle_pluggable_database.mp4"
import importASchema from "../../videos/oracle/Restore Oracle_pluggable_database_from_Export.mp4"

//import pdbStandby from "../../videos/oracle/"
import dropNode from "../../videos/General/Drop_a_node-general.mp4"
import deletebackup from "../../videos/General/Delete_Backup_or_Export.mp4"
import dropDatabase from "../../videos/General/Dropping_a_database-general.mp4"

import createPGCluster from "../../videos/postgres/Create_a_PG_cluster.mp4"
import restorePGNode from "../../videos/postgres/Restore_pg_cluster_from_backup.mp4"
import createPGstandby from "../../videos/postgres/Create_a_PG_standby.mp4"
import createPGDatabase from "../../videos/postgres/Create_a_PG_database.mp4"
import copyPGDatabase from "../../videos/postgres/Copy_a_PG_database.mp4"
import startAndStopPGNode from "../../videos/postgres/Start_and_stop_pg node.mp4"
import setPGNodeConfig from "../../videos/postgres/Set_PostgreSQL_node_config_and_limits.mp4"
import backupPGCluster from "../../videos/postgres/Backup_of_PG_cluster.mp4"
import backupPGDatabase from "../../videos/postgres/Backup_of_PG_database.mp4"
import restorePGDatabase from "../../videos/postgres/Restore_a_PG_database.mp4"
import switchoverPGCluster from "../../videos/postgres/Switchover_a_pg_cluster.mp4"



const OracleVideoSections = [
	{ title: "Register a CDB", description: "Register an Oracle container database at 123cluster", Ubtn: <YoutubeButton video={oracleRegisterCDB}></YoutubeButton> },

	{ title: "Create a PDB", description: "Create a pluggable database in an Oracle container database", Ubtn: <YoutubeButton video={createPDB}></YoutubeButton> },

	{ title: "Drop a PDB", description: "Drop a pluggable database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Open and close PDB", description: "Open and close a pluggable database", Ubtn: <YoutubeButton video={openAndClosePDB}></YoutubeButton> },

	{ title: "PDB settings, limits and name", description: "View and change pluggable database configuration, limits and name", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Duplicate from a standalone", description: "Duplicate an Oracle standalone into a pluggable database", Ubtn: <YoutubeButton video={duplicate}></YoutubeButton> },

	{ title: "Backup a PDB or a CDB", description: "Register an Oracle container database at 123cluster", Ubtn: <YoutubeButton video={backup}></YoutubeButton> },

	{ title: "Restore a PDB or a CDB", description: "Restore a pluggable database or a container Oracle database", Ubtn: <YoutubeButton video={restore}></YoutubeButton> },

	{ title: "Copy a PDB", description: "Copy a pluggable database within or between container databases", Ubtn: <YoutubeButton video={copyPDB}></YoutubeButton> },

	{ title: "Create restore points", description: "Create a pluggable or container database restore point", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Flashback to a restore point", description: "Flashback a pluggable database to a restore point", Ubtn: <YoutubeButton video={flashbackRestorePoint}></YoutubeButton> },

	{ title: "Deleting restore points", description: "Deleting pluggable or container database restore points", Ubtn: <YoutubeButton video={deleteRestorePoints}></YoutubeButton> },

	{ title: "Export a schema", description: "Export a schema from a pluggable or container database", Ubtn: <YoutubeButton video={exportASchema}></YoutubeButton> },

	{ title: "Import a schema", description: "Importing a schema into a pluggable or container database", Ubtn: <YoutubeButton video={importASchema}></YoutubeButton> },

	{ title: "PDB standby", description: "View and setup a pluggable database standby", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop an Oracle node", description: "Drop an Oracle node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Create a PDB", description: "Drop a backup or export at 123Cluster", Ubtn: <YoutubeButton video={deletebackup}></YoutubeButton> },

]

const PostgresVideoSections = [
	{ title: "Create a Postgresql cluster", description: "Create a PostgreSQL cluster at 123Cluster", Ubtn: <YoutubeButton video={createPGCluster}></YoutubeButton> },

	{ title: "Restore a node or cluster", description: "Restore a PostgreSQL node or cluster from Backup", Ubtn: <YoutubeButton video={restorePGNode}></YoutubeButton> },

	{ title: "Create a standby", description: "Create a PostgreSQL standby", Ubtn: <YoutubeButton video={createPGstandby}></YoutubeButton> },

	{ title: "Create a database", description: "Create a PostgreSQL database and users", Ubtn: <YoutubeButton video={createPGDatabase}></YoutubeButton> },

	{ title: "Copy a database", description: "Copy a PostgreSQL database", Ubtn: <YoutubeButton video={copyPGDatabase}></YoutubeButton> },

	{ title: "Start and Stop node", description: "Start and Stop a PostgreSQL node", Ubtn: <YoutubeButton video={startAndStopPGNode}></YoutubeButton> },

	{ title: "Set node config and limits", description: "Setting a postgreSQL node configuration and limits", Ubtn: <YoutubeButton video={setPGNodeConfig}></YoutubeButton> },

	{ title: "Backup a node or a cluster", description: "Backup a PostgreSQL node or cluster", Ubtn: <YoutubeButton video={backupPGCluster}></YoutubeButton> },

	{ title: "Backup a database", description: "Backup a PostgreSQL database", Ubtn: <YoutubeButton video={backupPGDatabase}></YoutubeButton> },

	{ title: "Restore a database", description: "Restore a PostgreSQL database", Ubtn: <YoutubeButton video={restorePGDatabase}></YoutubeButton> },

	{ title: "Cluster switchover", description: "Switchover between PostgreSQL nodes in the cluster", Ubtn: <YoutubeButton video={switchoverPGCluster}></YoutubeButton> },

	{ title: "Drop a Postgresql node", description: "Drop a PostgreSQL node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Drop a Postgresql database", description: "Drop a PostgreSQL database at 123Cluster", Ubtn: <YoutubeButton video={dropDatabase}></YoutubeButton> },

	{ title: "Drop a postgresql backup or export", description: "Drop a backup or export at 123Cluster", Ubtn: <YoutubeButton video={deletebackup}></YoutubeButton> },
]






const MySQLVideoSections = [
	{ title: "Create a node", description: "Create a MySQL node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a standby", description: "Create a MySQL standby", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Backup a node", description: "Backup MySQL node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a node", description: "Restore a MySQL node from Backup", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Start and stop a node", description: "Start and Stop a MySQL node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a node", description: "Drop a MySQL node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Cluster switchover", description: "Switchover between MySQL nodes in the cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a database", description: "Create a MySQL database and users", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Backup a database", description: "Backup a MySQL database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a database", description: "Restore a MySQL database ", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Copy a database", description: "Copy a MySQL database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a database", description: "Drop a MySQL database at 123Cluster", Ubtn: <YoutubeButton video={dropDatabase}></YoutubeButton> },

]

const MongoDBVideoSections = [

	{ title: "Create a node", description: "Create a MongoDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a standby or an arbiter", description: "Create a MongoDB standby or an arbiter", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Backup a node", description: "Backup a MongoDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a node", description: "Restore a MongoDB node from Backup", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Start and stop a node", description: "Start and Stop a MongoDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a node", description: "Drop a MongoDB node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Cluster stepdown", description: "Stepdown a MongoDB primary to standby", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a database", description: "Create a MongoDB database and users", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Backup a database", description: "Backup a MongoDB database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a database", description: "Restore a MongoDB database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Copy a database", description: "Copy a MongoDB database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a database", description: "Drop a MongoDB database at 123Cluster ", Ubtn: <YoutubeButton video={dropDatabase}></YoutubeButton> },


]

const MariaDBVideoSections = [
	{ title: "Create a node", description: "Create a MariaDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a standby", description: "Create a MariaDB standby", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Backup a node", description: "Backup MariaDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a node", description: "Restore a MariaDB node from Backup", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Start and stop a node", description: "Start and Stop a MariaDB node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a node", description: "Drop a MariaDB node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Cluster switchover", description: "Switchover between MariaDB nodes in the cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a database", description: "Create a MariaDB database and users", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Backup a database", description: "Backup a MariaDB database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a database", description: "Restore a MariaDB database ", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Copy a database", description: "Copy a MariaDB database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a database", description: "Drop a MariaDB database at 123Cluster", Ubtn: <YoutubeButton video={dropDatabase}></YoutubeButton> },

]

const RedisVideoSections = [

	{ title: "Create a standalone node", description: "Create a Redis standalone node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a cluster", description: "Create a Redis cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Backup a node", description: "Backup a Redis node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a node", description: "Restore a Redis node from Backup", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Start and stop a node", description: "Start and Stop a Redis node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a node", description: "Drop a Redis node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Cluster switchover", description: "Switchover between Redis nodes in the cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

]

const ElasticsearchVideoSections = [
	{ title: "Create a master node", description: "Create an Elasticsearch master node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Add a node", description: "Add the node to Elasticsearch cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Snapshot a node or cluster", description: "Snapshot an Elasticsearch node or cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a node", description: "Restore an Elasticsearch node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Start and stop a node", description: "Start and Stop an Elasticsearch node", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Drop a node", description: "Drop an Elasticsearch node at 123Cluster", Ubtn: <YoutubeButton video={dropNode}></YoutubeButton> },

	{ title: "Cluster switchover", description: "Switchover between Elasticsearch nodes in the cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create an index", description: "Create an Elasticsearch index", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Snapshot an index", description: "Snapshot an Elasticsearch index", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Restore an index", description: "Restore an Elasticsearch index", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Copy an index", description: "Copy an Elasticsearch index", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Close and open an index", description: "Close and open an Elasticsearch index", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop an index", description: "Drop an Elasticsearch index at 123Cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


]

const SQLServerVideoSections = [

	{ title: "Register an instance", description: "Register a SQLServer instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Unregister an instance", description: "Unregister a SQLServer instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Backup an instance", description: "Backup a SQLServer instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Export an instance", description: "Export a SQLServer instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Import an instance", description: "Import a SQLServer instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Create a database", description: "Create a SQLServer database and users", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Backup a database", description: "Backup a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Restore a database from backup", description: "Restore a SQLServer database from backup", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Close and open a database", description: "Close and open a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Rename a database", description: "Rename a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Copy a database", description: "Copy a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Copy a database to another instance", description: "Copy a SQLServer database to another instance", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Change a database cluster size", description: "Change the size of SQLServer cluster", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Export a database", description: "Export a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },


	{ title: "Import a database", description: "Import a SQLServer database", Ubtn: <YoutubeButton video={placeholder}></YoutubeButton> },

	{ title: "Drop a database", description: "Drop a SQLServer database", Ubtn: <YoutubeButton video={dropDatabase}></YoutubeButton> },
]

const OracleVideos = [
	createPDB,
	//oracleRegisterCDB,
	placeholder,
	placeholder,
	placeholder,
	duplicate,
	copyPDB,
	flashbackRestorePoint,
	backup,
	dropNode,

	// //pdbSettings,
	// //dropPDB, 
	// openAndClosePDB,


	// restore,

	// //createRestorePoint, 

	// deleteRestorePoints,
	// exportASchema,
	// importASchema,

]
const PostgreSQLVideos = [
	createPGCluster,
	placeholder,
	placeholder,
	placeholder,

	backupPGCluster,
	// copyPGDatabase,

	dropNode,



	// startAndStopPGNode,
	// setPGNodeConfig,

	// backupPGDatabase,
	// restorePGDatabase,
	// switchoverPGCluster,
]

const ElasticsearchVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]

const MariaDBVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]
const MongoDBVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]

const RedisVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]

const MySQLVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]

const SQLServerVideos = [
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
	placeholder,
]

export { OracleVideoSections, MySQLVideoSections, MongoDBVideoSections, MariaDBVideoSections, RedisVideoSections, PostgresVideoSections, ElasticsearchVideoSections, SQLServerVideoSections, OracleVideos, PostgreSQLVideos, MongoDBVideos, MariaDBVideos, MySQLVideos, RedisVideos, ElasticsearchVideos, SQLServerVideos }

