const PostgreSQL = [
    {
      title: "Create a cluster",
      instructions: [
        {
          text: "Click the 'add a new cluster' button and select PostgreSQL.",
          image: require("./pg_create_cluster1.png")
        },
        {
          text: "Fill the required information about the database cluster and click next.",
          image: require("./pg_create_cluster2.png")
        },
        {
          text: "Fill additional required information and click 'Check' to verify validity.",
          image: require("./pg_create_cluster3.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_create_cluster4.png")
        },
        {
          text: "Click 'Submit' to create the cluster."
        }
      ]
    },
    {
      title: "Restore a node or cluster",
      instructions: [
        {
          text: "Drag the selected backup onto the primary.",
          image: require("./pg_restore_cluster1.png")
        },
        {
          text: "Click the correct option for your restoration point.",
          image: require("./pg_restore_cluster2.png")
        },
        {
          text: "To avoid loss of data, type 'restore me' into the text box to confirm your decision.",
          image: require("./pg_restore_cluster3.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_restore_cluster4.png")
        },
        {
          text: "Click 'Submit' to initiate the restore."
        }
      ]
    },
    {
      title: "Create a standby",
      instructions: [
        {
          text: "Switch to the 'Standbys' tab on your cluster inventory.",
          image: require("./pg_create_standby1.png")
        },
        {
          text: "Drag the object labeled 'Drag me on to Primary to create a standby' onto the primary",
          image: require("./pg_create_standby2.png")
        },
        {
          text: "Fill in the required information for your standby node and click next.",
          image: require("./pg_create_standby3.png")
        },
        {
          text: "Fill additional required information and verify settings' validity by clicking the 'Check' button.",
          image: require("./pg_create_standby4.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_create_standby5.png")
        },
        {
          text: "Click 'Submit' to create the Standby."
        }
      ]
    },
    {
      title: "Create a database",
      instructions: [
        {
          text: "Switch to the 'Databases' tab on your cluster inventory.",
          image: require("./pg_create_database1.png")
        },
        {
          text: "Drag the object labeled 'Drag me on to Primary to create a database' onto the primary.",
          image: require("./pg_create_database2.png")
        },
        {
          text: "Choose a name for your database and click the 'Check' button to ensure it is available.",
          image: require("./pg_create_database3.png")
        },
        {
          text: "You may opt into the auto-delete function, your database will be deleted at your desired date.",
          image: require("./pg_create_database4.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_create_database5.png")
        },
        {
          text: "Click 'Submit' to create the Database."
        }
      ]
    },
    {
      title: "Start and stop a node",
      instructions: [
        {
          text: "Double click the primary node to enter the node configuration.",
          image: require("./pg_start&stop1.png")
        },
        {
          text: "To stop the node, click the 'Stop' button.",
          image: require("./pg_start&stop2.png")
        },
        {
          text: "The instance status is shown on the left, a red star indicates the node is stopped",
          image: require("./pg_start&stop3.png")
        },
        {
          text: "To restart the node, click the 'Start' button.",
          image: require("./pg_start&stop4.png")
        }
      ]
    },
    {
      title: "Set node config and limits",
      instructions: [
        {
          text: "Double click the primary node to enter the node configuration.",
          image: require("./pg_set_node_limits1.png")
        },
        {
          text: "These are the current setting of your node.",
          image: require("./pg_set_node_limits2.png")
        },
        {
          text: "Click the 'change' button to open the node settings",
          image: require("./pg_set_node_limits3.png")
        },
        {
          text: "Open the drop-down menu to modify your node",
          image: require("./pg_set_node_limits4.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_set_node_limits5.png")
        },
        {
          text: "Click 'Submit' to confirm your changes."
        }
      ]
    },
    {
      title: "Backup a node or a cluster",
      instructions: [
        {
          text: "Switch to your 'backup' tab on your cluster inventory.",
          image: require("./pg_backup_cluster1.png")
        },
        {
          text: "To create a backup, drag your primary node.onto your backups inventory.",
          image: require("./pg_backup_cluster2.png")
        },
        {
          text: "Select your desired backup strategy, standard backup or schedule a routine backup.",
          image: require("./pg_backup_cluster3.png")
        },
        {
          text: "Select a destination for your backup to be stored and whether or not you would like your backup to be compressed",
          image: require("./pg_backup_cluster4.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_backup_cluster5.png")
        },
        {
          text: "Click 'Submit' to start your backup."
        }
      ]
    },
    {
      title: "Backup a database",
      instructions: [
        {
          text: "Click the 'Physical layout' switch to view the databases in the cluster.",
          image: require("./pg_backup_database1.png")
        },
        {
          text: "Switch to your 'Import/Export' tab to view your database backup inventory.",
          image: require("./pg_backup_database2.png")
        },
        {
          text: "Drag your desired database onto your database inventory.",
          image: require("./pg_backup_database3.png")
        },
        {
          text: "Choose a name for your backup database, select additional options and click next",
          image: require("./pg_backup_database4.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_backup_database5.png")
        },
        {
          text: "Click 'Submit' to start your backup."
        }
      ]
    },
    {
      title: "Restore a database",
      instructions: [
        {
          text: "Switch to your 'Import/Export' tab to view your database inventory.",
          image: require("./pg_restore_database1.png")
        },
        {
          text: "Drag your desired backup onto your primary node.",
          image: require("./pg_restore_database2.png")
        },
        {
          text: "Type 'restore me' into the text box to avoid accidental loss of data.",
          image: require("./pg_restore_database3.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_restore_database4.png")
        },
        {
          text: "Click 'Submit' to restore."
        }
      ]
    },
    {
      title: "Drop a node",
      instructions: [
        {
          text: "Drag the node you with to drop onto the bin, can be either primary or a standby.",
          image: require("./pg_drop_node1.png")
        },
        {
          text: "Type 'drop me' into the text box to confirm your decision.",
          image: require("./pg_drop_node2.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_drop_node3.png")
        },
        {
          text: "Click 'Submit' to drop the node."
        }
      ]
    },
    {
      title: "Drop a database",
      instructions: [
        {
          text: "Click the 'Physical layout' switch to view the databases in the cluster.",
          image: require("./pg_drop_database1.png")
        },
        {
          text: "Drag the database you wish to drop onto the bin.",
          image: require("./pg_drop_database2.png")
        },
        {
          text: "Type 'drop me' into the text box to confirm your decision.",
          image: require("./pg_drop_database3.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_drop_database4.png")
        },
        {
          text: "Click 'Submit' to drop the database."
        }
      ]
    },
    {
      title: "Drop a backup or export",
      instructions: [
        {
          text: "Switch to your 'Import/Export' tab to view your database inventory.",
          image: require("./pg_drop_export1.png")
        },
        {
          text: "Drag the export you wish to drop onto the bin.",
          image: require("./pg_drop_export2.png")
        },
        {
          text: "'Rest API' Button will copy to clipboard for your DevOps tools.",
          image: require("./pg_drop_export3.png")
        },
        {
          text: "Click 'Yes' to drop the export."
        }
      ]
    }
  ];
  
  export default PostgreSQL;




