import React from 'react'

export default function NavigationLink({ id, text_id }) {


	return (
		<span data-id={id} className='span__link' key={id}>{text_id}</span>
	)

}

