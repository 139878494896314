
import React from 'react'

import "../../commonStyles/menu.scss"

export default function MenuItem({ img, text_id, id }) {
	return (
		<li key={text_id.concat(" li")} className="menu__list-item menu__list-item--active">
			<div key={text_id.concat(" link")} className="item__link" >
				<img key={text_id.concat("img")} className="list__img" alt={text_id.concat(" logo")} src={img} data-id={id} data_id={id} />
			</div>
		</li>
	)
}
