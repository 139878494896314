//import React from 'react'


import React, {useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Helmet } from 'react-helmet';
import { OracleVideoSections, PostgresVideoSections, MariaDBVideoSections, MongoDBVideoSections, MySQLVideoSections, RedisVideoSections, ElasticsearchVideoSections, SQLServerVideoSections } from './VideoSections';
import { animateScroll} from 'react-scroll';

import { useLocation } from 'react-router-dom';

import "./demos.scss"

export default function Demos() {

	const { pathname, hash, key } = useLocation();

	useEffect(() => {
		if (hash === '') {
			window.scrollTo(0, 0);
		}
		else {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}
	}, [pathname, hash, key]);


	const toTopBtnRef = useRef(null)

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	const handleScroll = () => {
		let scrolls = window.scrollY;
		if (scrolls > 0) toTopBtnRef.current.classList.add("totopbtn--active")
		else toTopBtnRef.current.classList.remove("totopbtn--active")
	};

	useEffect(() => {
		window.onkeydown = (e) => {
			let visibleDialog = document.querySelector(".p-dialog-visible")
			if ( visibleDialog !== null){
			let video = visibleDialog.querySelector("video")
				if (e.key === 'p'){
				if (!video.paused) video.pause()
				else if (video.paused) video.play()
				
				}
				
		}

		}
	})




	return (
		<div className='page'>
			<Helmet><title>123Cluster Demos</title></Helmet>
			<section className='demos'>
				<h1 className='demos__title'>123cluster Demos</h1>
				<div className='demos__database'>

					<div className="card card-table" id="Oracle">
						<DataTable tableStyle={{ minWidth: '50vw' }} breakpoint={{ "767.98": "100vw" }} resizableColumns columnResizeMode='fit' value={OracleVideoSections} header="Oracle Pluggable Database Demos" className="datatable">
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="PostgreSQL">
						<DataTable value={PostgresVideoSections} header="PostgreSQL Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="MySQL">
						<DataTable value={MySQLVideoSections} header="MySQL Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="MariaDB">
						<DataTable value={MariaDBVideoSections} header="MariaDB Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="MongoDB">
						<DataTable value={MongoDBVideoSections} header="MongoDB Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="Redis">
						<DataTable value={RedisVideoSections} header="Redis Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id='Elasticsearch'>
						<DataTable value={ElasticsearchVideoSections} header="Elasticsearch Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

					<div className="card card-table" id="SQL-Server">
						<DataTable value={SQLServerVideoSections} header="SQLServer Database Demos" tableStyle={{ minWidth: '50vw' }}>
							<Column field="title" header={undefined}></Column>
							<Column field="description" header={undefined} ></Column>
							<Column field="Ubtn" header={undefined}></Column>

						</DataTable>
					</div>

				</div>
				<button ref={toTopBtnRef} className='totopbtn' onClick={() => animateScroll.scrollToTop()}><i className='pi pi-chevron-circle-up'></i></button>
			</section>

		</div>
	)
}
