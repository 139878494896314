
import React, { useState } from "react"
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import validator from "../Validator/validator";
import constraints from "../Validator/constraints";
import { Card } from "primereact/card";

import "./contactForm.scss"
import axios from "axios";


export default function ContactsForm(props) {

	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [emailError, setEmailError] = useState("");
	const [messageError, setMessageError] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [subject, setSubject] = useState("");

	let firstNameRef = React.createRef();
	let lastNameRef = React.createRef();
	let emailRef = React.createRef();
	let subjectRef = React.createRef();
	let messageRef = React.createRef();

	const handleSubmit = (event) => {

		const errors = validate();
		if (errors.length === 0) {
			event.preventDefault()

			let data = {
				'firstname': firstNameRef.current.value,
				'lastname': lastNameRef.current.value,
				'email': emailRef.current.value,
				'subject': subjectRef.current.value,
				'message': messageRef.current.value
			}



			const response = axios.post('http://www.123cluster.com/api/feedback', data)
			console.log(response)
		}
	}
	const validate = () => {
		const errors = []
		let error = validator(constraints)("email", email);
		(error == null || errors.push(error)) && setEmailError(error);

		error = validator(constraints)("message", message);
		(error == null || errors.push(error)) && setMessageError(error);
		return errors;

	}



	return (
		<section key={props.text_id} className="form-section" id={props.text_id}>
			<div className="card__section">
				<div className="bg">
					<h1 className="title">ENQUIRE</h1>
					<p>Please use the contact form below to ensure your enquiry reaches the appropriate contant point</p>
				</div>
				<Card title={props.title} className="m-3 info-card" subtitle={props.subtitle} footer={props.footer} header={props.header}>
					<div style={{ flex: 1, padding: "10px" }} className="p-fluid form-container card">
						<div className="field grid">

							<div className="col">
								<div className="font-normal">
									First Name
								</div>
								<InputText value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" ref={firstNameRef} />


							</div>

							<div className="col">

								<div className="font-normal">
									Last Name
								</div>

								<InputText value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" ref={lastNameRef} />

							</div>

						</div>

						<div className="field grid">

							<div className="font-normal">
								Email {<span className="p-error">*</span>}
							</div>

							<InputText type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" ref={emailRef} />
							<div className="text-sm p-error">{emailError}</div>

						</div>

						<div className="field grid">

							<div className="font-normal">
								Subject
							</div>

							<InputText value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" ref={subjectRef} />

						</div>

						<div className="field grid">

							<div className="font-normal">
								Your Message {<span className="p-error">*</span>}
							</div>

							<InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Your Message" className="form-text-area" ref={messageRef} />
							<div className="text-sm p-error">{messageError}</div>

						</div>

						<div className="field flex"><Button className="p-button-success w-max ml-auto mr-auto" label="Submit Form" onClick={handleSubmit} /></div>
					</div>

				</Card>
				<div className="bg"></div>
			</div>
		</section>
	)

}

