import React from 'react'
import SpanMenu from '../SpanMenu/SpanMenu'
import "./VideoSection.scss"

export default function VideoSection({ titles, subtitles, description, video, id, text_id, isControls, isNormal, handle }) {

	let footer = (description) => {
		if (description.length !== 0) return description.map((string, index) => { return (<span key={index} className="description">{string}</span>) })
		else return <SpanMenu handle={handle}></SpanMenu>

	}
	let subtitlesArr = []
	let titlesArr = []

	return (

		<section id={text_id} className="section video-section" key={id}>
			{titles.map((string, index) => { titlesArr.push(<h1 key={index} className="title">{string}</h1>) 
			return <></>
		})}
		{titlesArr.map((title) => {return title})}
			

			{subtitlesArr = subtitles.map((string, index) => {
				let currentTitle = <></>
				if (isNormal) {
					if (index === subtitles.length - 1)  currentTitle = <p key={index} className="subtitle subtitle--normal">{string}</p>
					else currentTitle = <p key={index} className="subtitle subtitle--normal">{string}</p> 
				}
				else currentTitle = <p key={index} className="subtitle">{string}</p>
				subtitlesArr.push(currentTitle)
				
				return currentTitle
			}
			)}

			{
				isControls === true ? <video className="video" controls><source src={video} type='video/mp4'></source></video> : <video height={600} className="" autoPlay={true} loop={true} muted={true}><source src={video} type='video/mp4'></source></video>
			}

			{
				footer(description)
			}
		</section>

	)
}
