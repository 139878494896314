import React from 'react'

import NavigationLink from '../NavigationLink/NavigationLink'


import SideMenuItems from '../SideMenuItem/SideMenuItems'


import "./spanmenu.scss"


export default function SpanMenu({ delete_menu_id, id, handle }) {





	return (
		<span className="span__menu span" key={"span" + { id }} onClick={handle}>123Cluster is also available for
			{




				SideMenuItems.map((dataItem, index) => {

					if (dataItem.id !== delete_menu_id + 5) {

						if (index > 4 && index < 13) {

							if (delete_menu_id + 4 === 11) {
								if (index === 12) return (
									<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink></span>
								)
								if (index === 10) return (
									<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink> and</span>
								)
								else return (<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink>, </span>)
							}


							else if (delete_menu_id + 4 === 12) {
								if (index === 10) return (
									<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink> and</span>
								)
								if (index === 11) return (
									<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink></span>
								)
								else return (<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink>, </span>)
							}


							else if (index === 11) {
								return (<span data-id={dataItem.id} key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink> and </span>)
							}
							else if (index === 12) return (<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink></span>)

							else return (<span key={index} className='span'> <NavigationLink id={dataItem.id} className="span__link" key={dataItem.id} text_id={dataItem.text_id}></NavigationLink>, </span>)
						}
					}
					return null;
				}
				)


			}
		</span>

	)
}
