import React from 'react'
import ProductCard from '../../Components/ProductCard/ProductCard'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import './buy.scss'

import { useEffect } from 'react'
import axios, { AxiosHeaders } from 'axios'
import {PayPalScriptProvider} from "@paypal/react-paypal-js"

let month_and_prices = []

async function get_prices_from_server  (prices) {
	//if (!localStorage.getItem('prices')){
	let data = JSON.stringify(prices)
	const json_data = {"year_prices": data}
	
	//month_and_prices = await axios.post(`http://localhost:5000/prices/`, json_data, new AxiosHeaders("Access-Control-Allow-Origin: *")).then(async (response) => { return await response.data})
	

	console.log("bedore requst prices")
	month_and_prices = await axios.post('https://123cluster.com/prices', json_data, new AxiosHeaders("Access-Control-Allow-Origin: *; Content-Type: application/json; charset=utf-8")).then(async (response) => { return await response.data}).catch(
		function (err){
			if (err.response) {
				console.log(err.response.data)
				console.log(err.response.headers)
				console.log(err.response.status)
			}
			else if (err.request){
				console.log(err.request)
			}
			else {
				console.log("Error: ", err.message)
			}
			console.log(err.config)
		}
	)
	return month_and_prices
		// returning the data here allows the caller to get it through another .then(...)
		
	// 	const prices_and_month = await response.data
	// 	console.log(prices_and_month)
	// 	let result_prices = prices_and_month['prices']
	// 	console.log(result_prices)

	// 	return prices_and_month 
	// })
	// return month_and_prices
	
}
//}





export default function Buy() {

	const initialOptions = {
		  clientId: "AYWP0mB46i0CT4Pwc4baN3Cjz2Qwr4qXS2QvgpVjJAKzpNNDhCRDP6ZSRC9pMTuq9KE3mNR1rXj-G3rY",
		  currency: "USD",
		  intent: "capture",
		}
	

	
	
	useEffect(() => {

		handleLoad();
		
	}, []);


	const [prices, setPrices] = useState([])
	const [monthLeft, setMonthLeft] = useState(12)
	const [monthNames, setMonthNames] = useState([])
	const handleLoad = async () => {

		let month_and_prices = await get_prices_from_server([10, 20, 10, 20, 10, 20])
		if (month_and_prices['month_left'] !== undefined){
		setMonthLeft(month_and_prices['month_left'])
		setPrices(month_and_prices['prices'])
		setMonthNames(month_and_prices['month_names'])
		}
		console.log(month_and_prices)
		console.log("after request prices")
	}
	

	return (
	<PayPalScriptProvider options={initialOptions}>
	<div className='buy__page page'>
	<Helmet><title>123Cluster Products</title></Helmet>
	<h1 className='buy__title'>Oracle & SQLServer</h1>
	
	 <div className='product__container'>
		<ProductCard text_id = "FreeOracleCard" title = "Free" buttonLabel = "Sign in" description__items = {["Free monitoring module", "Unlimited nodes", "Node Dashboard", "Up to 10 daily notifications"]} span__items = {["No online support"]} moduleName="Oracle & SQLServer" monthNames = {monthNames}   ></ProductCard>
		<ProductCard text_id = "StandardOracleCard" title = "Standard" buttonLabel = "Buy" description__items = {["Full monitoring module", "Clustering", "Backup and restore", "Unlimited notifications"]} span__items = {["Mail support during work hours", "First month is free", "Full year subscription : 10$"]} price={prices[0] + "$"} month_left= {monthLeft} monthNames = {monthNames} moduleName="Oracle & SQLServer"   ></ProductCard>
		<ProductCard text_id = "AdvansedOracleCard" title = "Advanced" buttonLabel = "Buy" description__items = {["All modules", "Unlimited notifications", "Mail support 24X7", "First month is free"]} span__items = {["Full year subscription : 20$"]} price={prices[1] + "$"} month_left= {monthLeft} monthNames = {monthNames} moduleName="Oracle & SQLServer"  ></ProductCard>
	 </div>
	 
	 <h1 className='buy__title'>PostgreSQL, MySQL, MariaDB & MongoDB</h1>
	 <div className='product__container'>
		<ProductCard text_id = "FreeOracleCard" title = "Free" buttonLabel = "Sign in" description__items = {["Free monitoring module", "Unlimited nodes", "Node Dashboard", "Up to 10 daily notifications"]} span__items = {["No online support"]} moduleName="PostgreSQL, MySQL, MariaDB & MongoDB"  monthNames={monthNames}></ProductCard>
		<ProductCard text_id = "StandardOracleCard" title = "Standard" buttonLabel = "Buy" description__items = {["Full monitoring module", "Clustering", "Backup and restore", "Unlimited notifications"]} span__items = {["Mail support during work hours", "First month is free", "Full year subscription : 10$"]} price={prices[2]+"$"} month_left= {monthLeft} moduleName='PostgreSQL, MySQL, MariaDB & MongoDB'  monthNames={monthNames}></ProductCard>
		<ProductCard text_id = "AdvansedOracleCard" title = "Advanced" buttonLabel = "Buy" description__items = {["All modules", "Unlimited notifications", "Mail support 24X7", "First month is free"]} span__items = {["Full year subscription : 20$"]} price = {prices[3]+"$"} month_left= {monthLeft} moduleName='PostgreSQL, MySQL, MariaDB & MongoDB' monthNames={monthNames}  ></ProductCard>
	 </div>

	 <h1 className='buy__title'>Redis & ElasticSearch</h1>
	 <div className='product__container'>
		<ProductCard text_id = "FreeOracleCard" title = "Free" buttonLabel = "Sign in" description__items = {["Free monitoring module", "Unlimited nodes", "Node Dashboard", "Up to 10 daily notifications"]} span__items = {["No online support"]} moduleName="Redis & Elasticsearch"  ></ProductCard>
		<ProductCard text_id = "StandardOracleCard" title = "Standard" buttonLabel = "Buy" description__items = {["Full monitoring module", "Clustering", "Backup and restore", "Unlimited notifications"]} span__items = {["Mail support during work hours", "First month is free", "Full year subscription : 10$"]} month_left= {monthLeft} price={prices[4]+"$"} moduleName="Redis & Elasticsearch" monthNames = {monthNames}  ></ProductCard>
		<ProductCard text_id = "AdvansedOracleCard" title = "Advanced" buttonLabel = "Buy" description__items = {["All modules", "Unlimited notifications", "Mail support 24X7", "First month is free"]} span__items = {["Full year subscription : 20$"]} price = {prices[5]+"$"} month_left= {monthLeft} moduleName="Redis & Elasticsearch" monthNames = {monthNames}  > </ProductCard>
	 </div>

	</div>
	</PayPalScriptProvider>
	
  )
}
