
import elastic from "./images/Elastic.png"
import postgres from "./images/postgresql.png"
import mariadb from "./images/mariadb.png"
import redis from "./images/Redis.png"
import mongo from "./images/mongodb.png"
import sql from "./images/sql-server.png"
import oracle from "./images/oracle.png"
import mysql from "./images/mysql.png"


const SideMenuItems = [
	{
		id: 1,
		title: "123 Cluster",
		text_id: "123 Cluster"
	},
	{
		id: 2,
		title: "Our mission",
		text_id: "Our mission"
	},
	// {
	// 	id: 2,
	// 	title: "Database Life Cicle",
	// 	text_id: "Cicle"
	// },

	{
		id: 3,
		title: "Intuitive",
		text_id: "intuitive"
	},

	{
		id: 4,
		title: "Private Database Cloud",
		text_id: "cloud",
	},



	{
		id: 5,
		title: "REST API",
		text_id: "RestAPI"
	},
	{
		id: 6,
		title: "Oracle",
		text_id: "Oracle",
		img: oracle
	},
	{
		id: 7,
		title: "Mysql",
		text_id: "MySQL",
		img: mysql
	},
	{
		id: 8,
		title: "MariaDB",
		text_id: "MariaDB",
		img: mariadb
	},
	{
		id: 9,
		title: "MongoDB",
		text_id: "MongoDB",
		img: mongo
	},
	{
		id: 10,
		title: "PostgreSQL",
		text_id: "PostgreSQL",
		img: postgres
	},
	{
		id: 11,
		title: "Redis",
		text_id: "Redis",
		img: redis
	},

	{
		id: 12,
		title: "SQL Server",
		text_id: "SQL-Server",
		img: sql
	},
	{
		id: 13,
		title: "Elasticsearch",
		text_id: "Elasticsearch",
		img: elastic
	},
	{
		id: 14,
		title: "Contact Us",
		text_id: "contactsForm"
	},
	{
		id: 15,
		title: "Customers",
		text_id: "customers"
	},
	{
		id: 16,
		title: "Footer",
		text_id: "Footer"
	},

]

export default SideMenuItems