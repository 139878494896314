
import React, { useEffect, useState } from "react"

import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import validator from "../Validator/validator";
import constraints from "./constraints.js";
import { Card } from "primereact/card";

import "./buyForm.scss"
import axios from "axios";


import { PayPalButtons } from "@paypal/react-paypal-js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import {FUNDING} from "@paypal/react-paypal-js"
import { v4 as uuidv4 } from 'uuid'





export default function BuyForm(props) {

	const [email, setEmail] = useState(props.passed_email);
	const [emailError, setEmailError] = useState("");
	const [nodesNumber, setNodesNumber] = useState(1);
	const [nodesNumberError, setNodesNumberError] = useState("");
	const [paypalError, setPaypalError] = useState('')


	const [succeeded, setSucceeded] = useState(false);
	const [monthNames, setMonthNames] = useState('')
	const [disabled, setDisabled] = useState(props.disabled)
	const [dialog_visible, setVisible] = useState(false)
	const [productKey, setProductKey] = useState('')

	let emailRef = React.createRef();
	let nodesNumberRef = React.createRef();


	useEffect (() => {console.log(productKey?.product_key)}, [disabled, productKey])


	 useEffect(() => {
		if (succeeded){
		setVisible(true)
		}
	}, [succeeded])

	const getButtons = () => {
		if (props.type === "Free" || props.monthLeft <=2) return(<Button className="p-button-success w-max ml-auto mr-auto" label= 'Get product key' disabled={disabled} onClick={() => handleApprove()}></Button>)
		else return (<Button className="p-button-success w-max ml-auto mr-auto"label="Continue to PayPal" onClick={() => handleSubmit()} disabled = {disabled}></Button>)
	}

	
	const  handleApprove = async (orderId) => {
		
		// let data = {"email" : emailRef.current.value}
		// await axios("http://localhost:5000/create/", data).then((response)=> {console.log(response.data)})

		let module_name 
		let module_type 
		if (props.moduleName) {
			module_name = props.moduleName
		}
		else module_name = localStorage.getItem('module_name')
		if (props.type) module_type = props.type
		else module_type = localStorage.getItem('module_type')
		
		let nodes_number
		if (nodesNumberRef.current) nodes_number = nodesNumberRef.current.value
		else nodes_number = 1
		
		let data = {"email": emailRef.current.value, 'module_name': module_name, 'module_type': module_type, 'nodes_number': nodes_number}
		// let result = await axios.post(`https://www.123cluster.com/create`, data, {headers: {
        // "Content-Type": "application/json"}}).then((response)=> {return response.data}).catch(err => {console.error(err)})
		console.log('module_type: ' + module_type)
		let result = await axios.post("https://www.123cluster.com/create", data).then((response)=> 
		{
			if (response.status === 200)localStorage.clear()
			return response.data


		})
		setProductKey(result)
		
		// let result = await axios.post("http://localhost:5000/create/", data).then((response)=> 
		// {
		// 	if (response.status === 200)localStorage.clear()
		// 	return response.data


		// })
		// setProductKey(result)
		
		
		setSucceeded(true)
		
		

		
	}
	
	
	const createOrder = (data, actions) => {
		let price 
		if (props.price){ price = props.price}
		else  {
			price = localStorage.getItem('price')
		}
		return actions.order.create({
			
			purchase_units: [{
			   amount: {
				 currency_code: "USD",
				 value: nodesNumberRef.current.value * price,
				 breakdown: {
				   item_total: {  /* Required when including the `items` array */
					 currency_code: "USD",
					 value: nodesNumberRef.current.value * price
				   }
				 }
			   },
			   items: [
				 {
				   name: `${props.type} module for ${props.moduleName}`, 
				   description: `${props.type} module for ${props.moduleName} `,
				   unit_amount: {
					 currency_code: "USD",
					 value: price
				   },
				   quantity: nodesNumberRef.current.value
				 },
			   ]
			 }]
		 });
	  }
	  const onApprove = async (data, actions) => {
		const order = await actions.order.capture()  
		handleApprove (data.orderId)
		console.log(order)
		
	  };

	  const onError = async (error) => {
		setPaypalError(error);
	  }
	
	
	

	const validate = () => {
		const errors = []
		let error = validator(constraints)("email", email);
		(error == null || errors.push(error)) && setEmailError(error);

		error = validator(constraints)("nodesNumber", nodesNumber);
		(error == null || errors.push(error)) && setNodesNumberError(error);
		return errors;

	}



	const handleValidate = async () => {
		const errors = validate()
		if(errors.length > 0){ 
			
			setDisabled(true)
		}

			else {
				// const obj = {'email': emailRef.current.value, 'module_name': props.moduleName, 'module_type': props.type, 'uuid': }
				const uuid = uuidv4()

				

				const obj = {'email': emailRef.current.value, 'module_name': props.moduleName, 'module_type': props.type, 'uuid': `${uuid}` }
				localStorage.setItem(`${uuid}`, JSON.stringify(obj))
				localStorage.setItem('price', JSON.stringify(props.price))
				localStorage.setItem('module_name', JSON.stringify(props.moduleName))
				localStorage.setItem('module_type', JSON.stringify(props.title))
				// await axios.post(`http://localhost:5000/verify/`, obj, {headers: {
				// 	'Content-Type': 'application/json',
				// }}).then((response) => {
				// 	if ( response.status === 200 && errors.length === 0 ){
				// 		errors.push(response.data) && setEmailError(response.data)
				// 	}
				// 	else {
				// 		errors.push(response.data) && setEmailError(response.data)
												
				// 	}
				// })
				await axios.post(`https://www.123cluster.com/verify`, obj, {headers: {
					'Content-Type': 'application/json',
				}}).then((response) => {
					if ( response.status === 200 && errors.length === 0 ){
						errors.push(response.data) && setEmailError(response.data)
					}
					else {
						errors.push(response.data) && setEmailError(response.data)
												
					}
				})


			}
	} 

	const  handleSubmit = () => {
		
		const errors = validate()
		if(errors.length > 0) 
			console.log(errors)
			else {
				let monthNamesString = ``
				if (props.monthNames.length > 2){
				monthNamesString = `* You pay only for ${props.monthNames[2]}`
				
				monthNamesString = monthNamesString.concat(`. Rest of ${props.monthNames[0]} and ${props.monthNames[1]} is free.`)
				}
				else {
					 monthNamesString = `Rest of ${props.monthNames[0]} and ${props.monthNames[1]} is free`
				}
				setMonthNames(monthNamesString)
				
				document.getElementsByClassName("hidden")[0]?.classList.remove("hidden")

			}
		}
		
	let getNumberNodesBlock = () => {
		if (props.type === "Free") return <></>
		else return( 		
		<div className="field grid">
		<div className="font-normal">
			Number of nodes {<span className="p-error">*</span>}
		</div>

		<InputText type="number" value={nodesNumber} onChange={(e) => setNodesNumber(e.target.value)} placeholder="Number of nodes you want to pay" ref={nodesNumberRef} disabled={disabled} />
		<div className="text-sm p-error">{nodesNumberError}</div>
	
	</div>
		)
	}
	
	useEffect( () => {
		if (paypalError) console.error(paypalError);
	}, [paypalError])

	return (
	
		<section className="buy-form">
			<div className="buy__block">
					<p className="buy__title">You selected {props.type} module for {props.moduleName}</p>
				</div>
				<Card>
					<div style={{ flex: 1, padding: "10px" }} className="p-fluid form-container card">
						
						<div className="field grid">

							<div className="font-normal">
								Email {<span className="p-error">*</span>}
							</div>

							<InputText type="email" value={email} onChange={(e) => {
								const passed_email = localStorage.getItem('email') 
								if (passed_email) setEmail(passed_email)
								else setEmail(e.target.value)}} placeholder="Email" ref={emailRef} disabled={!disabled}/>
							<div className="text-sm p-error">{emailError}</div>

						</div>
						
						{getNumberNodesBlock()}
				
					
				<div className="field flex">
				<Button className="p-button-success w-max ml-auto mr-auto"label="Verify Email" onClick={() => handleValidate()} disabled={!disabled}></Button>				
				{props.monthNames && getButtons()}
				</div>

				{/*<PayPalButtons createOrder={(data, actions)=> createOrder(data, actions)} onApprove={(data, actions)=> onApprove(data, actions)} className="hidden"></PayPalButtons>*/}
				
				</div>

				</Card>
				
				<div className="hidden pay-block">
				{/* <p className="buy__title">{monthNames}</p>	 */}
				<DataTable value = {[{"Name": `${props.type} module for ${props.moduleName}`, "Price": `${props.price}$`, "Number": nodesNumber, "Total": `${props.price * nodesNumber}$`}]}showGridlines tableStyle={{ minWidth: '50rem' }}>
   					
   					 <Column field="Name" header="Module"></Column>
					 {/* <Column field="Month Names" header="Month names"></Column> */}
					 <Column field="Price" header="Price USD*"></Column>
   					 
					 <Column field="Number" header="Number of nodes"></Column>
					 
					 <Column field="Total" header="Total USD" className="bold-text"></Column>
				</DataTable>

				<p className="buy__note">{monthNames}</p>

				<div className="pay-button">
				
				<PayPalButtons  createOrder={(data, actions)=> createOrder(data, actions)} onApprove={(data, actions)=> onApprove(data, actions)} onError={(err) => onError(err)} fundingSource={FUNDING.PAYPAL}></PayPalButtons>
				<Dialog draggable={false} dismissableMask visible={dialog_visible} onHide={() => {
				 setSucceeded(false)
				 setVisible(false)
				 window.location.reload(true);
				 
				 document.body.classList.remove("_lock")
			}} className="dialog">
				
				<div>
					 {productKey?.product_key && <p> Thank you for chosen 123Cluster. Your product key: {productKey?.product_key} </p>}
				</div>
			</Dialog>
				</div>
					
			</div>
			
		</section>
				
	)

	}

