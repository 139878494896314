import React from 'react';
import Action from '../Action/Action';
import "../../App.scss";

import { NavLink } from "react-router-dom"


import { PostgreSQLVideos, OracleVideos, MySQLVideos, MongoDBVideos, MariaDBVideos, RedisVideos, ElasticsearchVideos, SQLServerVideos } from "../../Pages/Demos/VideoSections";
import SpanMenu from '../SpanMenu/SpanMenu';








function DatabaseSection({ id, titles, subtitles, bgImg, description, classes, delete_menu_id, actions, text_id, handle, isBiggest, sectionRef }) {

	let videos = []
	if (text_id === "Oracle") videos = OracleVideos
	else if (text_id === "PostgreSQL") {
		videos = PostgreSQLVideos
	}
	else if (text_id === "MariaDB") videos = MariaDBVideos
	else if (text_id === 'MongoDB') videos = MongoDBVideos
	else if (text_id === 'MySQL') {
		videos = MySQLVideos
	}
	else if (text_id === "Redis") videos = RedisVideos
	else if (text_id === "Elasticsearch") videos = ElasticsearchVideos
	else if (text_id === "SQL-Server") {
		videos = SQLServerVideos


	}

	let descriptions = Array.from(description)

	return (


		<section key={id} className={classes} id={text_id} ref={sectionRef}>

			{text_id === "Oracle" || text_id === "123cluster" ? <video src={bgImg} autoPlay={true} muted={true} className="section__bg"></video> : <video src={bgImg} autoPlay={true} muted={true} loop={true} className="section__bg"></video>}

			<div className="text" key={"text ".concat(id)}>
				{titles.map((string, index) => { return <h1 key={index} className="title">{string}</h1> })}
				{subtitles.map((string, index) => { return <p key={index} className="subtitle">{string}</p> })}

				<div className='action__container'>

					{actions.map((dataItem, index) => {

						return (<Action key={"".concat(text_id) + index} title={dataItem.title} img={dataItem.img} video={videos[index]} ></Action>)
					})}

					{actions.length === 0 ? <></> : <NavLink className="item__link" to={`/demos/#${text_id}`} target="_blank"><i className='pi pi-ellipsis-v action__img action__more'></i> more</NavLink>}




					{
						//<img className="action__img" src={moreImg} alt={"more img"} key={text_id.concat("more img")} />
					}
				</div>
				{


					descriptions.map((string, index) => {

						if (!isBiggest) {
							let description_classes = ""
							index === descriptions.length - 1 ? description_classes += "description description--big" : description_classes += "description"
							return <p key={index} className={description_classes} >{string}</p>
						}
						else {
							let description_classes = ""
							index === description.length - 1 ? description_classes += "description description--bigest" : description_classes += "description"
							return <p key={index} className={description_classes} >{string}</p>
						}


					})
				}

				<SpanMenu className="span__menu" id={id} delete_menu_id={delete_menu_id} handle={handle}></SpanMenu>
			</div>
		</section >
	)
}

export default DatabaseSection

