import React, { useState, useEffect, useRef } from 'react'

import { Carousel } from 'primereact/carousel';


import { Helmet } from "react-helmet";

import DatabaseSection from "../../Components/DatabaseSection/DatabaseSection";
import DatabaseSections from "../../Components/DatabaseSection/DatabaseSections";




import SideMenuItem from "../../Components/SideMenuItem/SideMenuItem";
import SideMenuItems from "../../Components/SideMenuItem/SideMenuItems";

//import CarouselItem from "../Components/CarouselItem/CarouselItem";
//import CarouselItems from "../Components/CarouselItem/CarouselItems";

import Footer from "../../Components/Footer/Footer";

import ContactsForm from "../../Components/ContactsForm/ContactsForm";

import VideoSection from "../../Components/VideoSection/VideoSection";
import VideoSections from "../../Components/VideoSection/VideoSections";

import FeedbackItem from "../../Components/FeedbackItem/FeedbackItem";
import feedbackItems from "../../Components/FeedbackItem/FeedbackItems";

import InfoCard from "../../Components/InfoCard/InfoCard";
import MenuItem from "../../Components/MenuItem/MenuItem";
import { scroller } from 'react-scroll';

import backup from "../../Components/DatabaseSection/images/backup-2.png"
import copy from "../../Components/DatabaseSection/images/copy.png"
import create from "../../Components/DatabaseSection/images/create.png"
import drop from "../../Components/DatabaseSection/images/drop.png"
import duplicate from "../../Components/DatabaseSection/images/duplicate.png"

import ha from "../../Components/DatabaseSection/images/ha.png"
import manage from "../../Components/DatabaseSection/images/manage.png"
import monitor from "../../Components/DatabaseSection/images/monitor.png"
import { ElasticsearchVideos, MariaDBVideos, MongoDBVideos, MySQLVideos, OracleVideos, PostgreSQLVideos, RedisVideos, SQLServerVideos } from '../Demos/VideoSections';
import Header from '../../Components/Header/Header';

import "./home.scss"







export default function Home() {



	const toTopBtnRef = useRef(null)
	const SideMenuRef = useRef(null)
	const oracleSectionRef = useRef(null)
	const firstScreenRef = useRef(null)
	const menuRef = useRef(null)
	let isScrolling = null
	let enableScroll = null
	const videoPauseTimeout = useRef(0);
	let [scroll, setScroll] = useState({ offset: 0, currentsectionIndex: 0 });

	React.useEffect(() => {
		if (!oracleSectionRef.current || !firstScreenRef.current) {return;}

		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.1
		};

		const menuObserver = new IntersectionObserver ((entries, observer) => {
			
			entries.forEach((entry) => {
				if (entry.isIntersecting){
					let timeout = 0
					const menu_items = entry.target.querySelectorAll('.menu__list-item')
					
					if (menu_items){
						menu_items.forEach ((item => {
							if (item.classList.contains("menu__list-item--active")) item.classList.remove("menu__list-item--active")
						}))
						for (let i = 0; i< menu_items.length; i++){
						setTimeout(() => {menu_items[i].classList.add("menu__list-item--active")}, timeout)
						timeout+=700 
					}
				}

				}
			})
		})

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry => {
				if (entry.isIntersecting ) {
					
					
					const videoEl = entry.target.querySelector('video')
					
					if (videoEl) {
						if (videoPauseTimeout.current !== 0) clearTimeout(videoPauseTimeout.current) 
						videoEl.currentTime = 0
						videoEl.play()
						if (firstScreenRef) 
						videoPauseTimeout.current = setTimeout(() => {
							videoEl.pause()
						}, 9000)
						

					}

				}
			}))
		}, options)
		
		observer.observe(oracleSectionRef.current);
		observer.observe(firstScreenRef.current);
		menuObserver.observe(menuRef.current)
		
		return () => { 
			observer.disconnect();
			menuObserver.disconnect()
			}
	}, []);



	useEffect(() => {
		window.addEventListener("wheel", handleScroll)
		window.onkeydown = (e) => {

			if ((e.key === "ArrowDown" || e.key === "PageDown") && scroll.currentsectionIndex < SideMenuItems.length - 1 && !document.body.classList.contains("_lock")) {
				setScroll({ offset: window.scrollY, index: scroll.currentsectionIndex + 1 })
				scroller.scrollTo(SideMenuItems[scroll.currentsectionIndex].text_id, { smooth: true })
				if (scroll.currentsectionIndex > 0) document.querySelector("header").classList.remove("header--hidden")

				let active_element = document.getElementsByClassName("side__menu-item--active")[0]
				active_element.classList.remove("side__menu-item--active")
				let next_active = active_element?.nextSibling
				next_active.classList.add("side__menu-item--active")
			}
			else if ((e.key === "ArrowUp" || e.key === "PageUp") && scroll.currentsectionIndex > 0 && !document.body.classList.contains("_lock")) {
				setScroll({ offset: window.scrollY, index: scroll.currentsectionIndex - 1 })
				scroller.scrollTo(SideMenuItems[scroll.currentsectionIndex].text_id, { smooth: true })
				
				let active_element = document.getElementsByClassName("side__menu-item--active")[0]
				active_element.classList.remove("side__menu-item--active")
				let prev_active = active_element?.previousSibling
				prev_active.classList.add("side__menu-item--active")
			
				if (prev_active === document.querySelectorAll(".side__menu-item")[0]) document.querySelector("header").classList.add("header--hidden")
			}

		}
		return () => {
			window.removeEventListener("wheel", handleScroll);

		}
	});

	useEffect(() => {
		window.addEventListener("load", handleLoad);
		return () => {
			window.removeEventListener("load", handleLoad);
		};
	});

	setScroll = ({ ...newScroll }) => {
		scroll.offset = newScroll.offset
		scroll.currentsectionIndex = newScroll.index

	}


	const handleLoad = () => {
		scroller.scrollTo(SideMenuItems[0].text_id, { smooth: true })
		setScroll({ offset: 0, index: 0 })
		document.getElementsByClassName("side__menu-item")[0].classList.add("side__menu-item--active")
		document.body.classList.add('home__page')
	}


	const handleScroll = () => {

		if (!document.body.classList.contains("_lock")){
		const header = document.querySelector("header")
		let currentOffset = window.scrollY
		let isScrollDown = true ? currentOffset > scroll.offset : false
		setScroll({ offset: currentOffset, index: scroll.currentsectionIndex })

		if (enableScroll != null) clearTimeout(enableScroll)


		if (currentOffset > 0){ 
			toTopBtnRef.current.classList.add("totopbtn--active")
			if (header.classList.contains("header--hidden")) header.classList.remove("header--hidden")
		}
		else {
			toTopBtnRef.current.classList.remove("totopbtn--active")
			if (!header.classList.contains("header--hidden")) header.classList.add("header--hidden")
		}

		if (isScrolling !== null) {
			clearTimeout(isScrolling)
		}

		if (isScrolling !== null) clearTimeout(isScrolling)
		isScrolling = setTimeout(() => {
			if (isScrollDown && scroll.currentsectionIndex < SideMenuItems.length - 1 && !document.body.classList.contains("_lock")) {
				setScroll({ offset: currentOffset, index: scroll.currentsectionIndex + 1 })
				scroller.scrollTo(SideMenuItems[scroll.currentsectionIndex].text_id, {
					smooth: true,
					duration: 500,
					delay: 0,
					offset: 0
				})
				document.body.classList.add("_lock")
				let active_element = document.getElementsByClassName("side__menu-item--active")[0]
				active_element?.classList.remove("side__menu-item--active")
				let next_active = active_element?.nextSibling
				next_active?.classList.add("side__menu-item--active")

			}
			else if (!isScrollDown && scroll.currentsectionIndex > 0 && !document.body.classList.contains("_lock")) {
				setScroll({ offset: currentOffset, index: scroll.currentsectionIndex - 1 })
				scroller.scrollTo(SideMenuItems[scroll.currentsectionIndex].text_id, {
					smooth: true,
					duration: 300,
					delay: 0,
					offset: 0
				})
				document.body.classList.add("_lock")
				let active_element = document.getElementsByClassName("side__menu-item--active")[0]
				active_element?.classList.remove("side__menu-item--active")
				let prev_active = active_element?.previousSibling;
				prev_active?.classList.add("side__menu-item--active")
			}
		}, 150)


		clearTimeout(enableScroll)
		enableScroll = setTimeout(() => {
			document.body.classList.remove("_lock")
		}, 500)

	}
	}

	let handleClick = (e) => {
		if (!document.body.classList.contains("_lock")){
		let newActiveIndex = parseInt(e.target.getAttribute("data-id")-1)
		document.body.classList.add("_lock")
		document.getElementsByClassName("side__menu-item--active")[0]?.classList.remove("side__menu-item--active")
		document.getElementsByClassName("side__menu-item")[parseInt((newActiveIndex))]?.classList.add("side__menu-item--active")

		setScroll({ offset: window.scrollY, index: newActiveIndex })
		scroller.scrollTo(SideMenuItems[parseInt(newActiveIndex)]?.text_id, { smooth: true })
		if (newActiveIndex === 0){
			if (toTopBtnRef.current.classList.contains("totopbtn--active")) toTopBtnRef.current.classList.remove("totopbtn--active")
		}
		else if (!toTopBtnRef.current.classList.contains("totopbtn--active")) toTopBtnRef.current.classList.add("totopbtn--active")
		isScrolling = setTimeout(() => {
			if (document.body.classList.contains("_lock")) document.body.classList.remove("_lock")
			let header = document.querySelector("header")
			if (header.classList.contains('header--hidden') && newActiveIndex !== 0) header.classList.remove('header--hidden')
			else if (!header.classList.contains('header--hidden') && newActiveIndex === 0) header.classList.add('header--hidden')
		
		}, 150)
	}
	}

	return (

		<div className="page home__page">
			<Helmet><title>123Cluster</title></Helmet>

			<Header></Header>
			
			<section className="section video-section" key={"123 Cluster"} id="123 Cluster" handle={handleClick}>
			<div className='section__content'>
			<h1 className="title">{VideoSections[0].titles[0]}</h1>
			<p className="subtitle">{VideoSections[0].subtitles[0]}</p>
			<nav className="menu" ref={menuRef}>
					<ul onClick={handleClick} key={"menu__list__first"} className="menu__list">
						{
							SideMenuItems.map((dataItem) => {
								if (dataItem.img && dataItem.id <= 9) {
									return (
										<MenuItem img={dataItem.img} key={dataItem.id} alt={dataItem.text_id + " logo"} id={dataItem.id} text_id={dataItem.text_id}></MenuItem>
									)
								}
								
								return <></>
							})
						}
					</ul>
					
				
					<ul onClick={handleClick} key={"menu__list__second"} className="menu__list">
						{
							SideMenuItems.map((dataItem) => {
								if (dataItem.img && dataItem.id > 9) {
									return (
										<MenuItem img={dataItem.img} key={dataItem.id} alt={dataItem.text_id + " logo"} id={dataItem.id} text_id={dataItem.text_id}></MenuItem>
									)
								}
								return <></>

							})
						}
					</ul>
				</nav>
				</div>
			
			
			<div className='video__block' ref={firstScreenRef}>
			<video height={850} width={850} autoPlay={true}  muted={true}><source src={VideoSections[0].video} type='video/mp4'></source></video>
			</div>
			


			<div key={"side-menu"} className="side-menu" ref={SideMenuRef} onClick={handleClick}>
					{	
						SideMenuItems.map((dataItem) => { return (<SideMenuItem key={"sideMenu".concat(dataItem.id)} title={dataItem.title} text_id={dataItem.text_id} className="side__menu-item" id={dataItem.id}></SideMenuItem>) })
					}
			</div>
			</section>
			
			{/* <section key={"123 Cluster"} id="123 Cluster" className="section">
				<Carousel value={CarouselItems} itemTemplate={CarouselItem} autoplayInterval={5000} circular className="databases-slider"></Carousel>
				<div key={"side-menu"} className="side-menu" ref={SideMenuRef} onClick={handleClick}>

					{
						SideMenuItems.map((dataItem) => { return (<SideMenuItem key={"sideMenu".concat(dataItem.id)} title={dataItem.title} text_id={dataItem.text_id} className="side__menu-item" id={dataItem.id}></SideMenuItem>) })
					}

				</div>

			</section> */}

			{/* <InfoCard id="What we do" title="Database high availability in minutes" description={[""]}></InfoCard> */}
			<InfoCard id="Our mission" title="Our mission" handle={handleClick}></InfoCard>
		



			{
				VideoSections.map((dataItem, index) => {

					if (index > 0){
					let isNormal
					index === 3 ? isNormal = true : isNormal = false
					return (<VideoSection titles={dataItem.titles} key={dataItem.id} subtitles={dataItem.subtitles} description={dataItem.description} id={dataItem.id} text_id={dataItem.text_id} video={dataItem.video} isControls={dataItem.isControls} isNormal={isNormal} handle={handleClick}></VideoSection>)
					}
					return <></>
				})
			}
			{
				DatabaseSections.map((dataItem, index) => {
					let actions = []
					if (dataItem.id === 1) {

						actions = [
							{ title: "create", img: create, video: OracleVideos[0] },
							{ title: "HA", img: ha, video: OracleVideos[1] },
							{ title: "monitor", img: monitor, video: OracleVideos[2] },
							{ title: "manage", img: manage, video: OracleVideos[3] },
							{ title: "duplicate", img: duplicate, video: OracleVideos[4] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							//{ title: "restore point & flashback", img: flash, video: OracleVideos[6] },
							{ title: "backup", img: backup, video: OracleVideos[7] },
							{ title: "drop", img: drop, video: OracleVideos[8] },
						]
					}

					if (dataItem.id === 2) {
						actions = [
							{ title: "create", img: create, video: MySQLVideos[0] },
						
							{ title: "HA", img: ha, video: MySQLVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: MySQLVideos[2] },
							{ title: "manage", img: manage, video: MySQLVideos[3] },
							{ title: "backup & restore", img: backup, video: MySQLVideos[4] },
							{ title: "drop", img: drop, video: MySQLVideos[5] },
						]
					}

					if (dataItem.id === 3) {
						actions = [
							{ title: "create", img: create, video: MariaDBVideos[0] },
							
							{ title: "HA", img: ha, video: MariaDBVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: MariaDBVideos[2] },
							{ title: "manage", img: manage, video: MariaDBVideos[3] },
							{ title: "backup & restore", img: backup, video: MariaDBVideos[4] },
							{ title: "drop", img: drop, video: MariaDBVideos[5] },
						]
					}

					if (dataItem.id === 4) {
						actions = [
							{ title: "create", img: create, video: MongoDBVideos[0] },
							{ title: "HA", img: ha, video: MongoDBVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: MongoDBVideos[2] },
							{ title: "manage", img: manage, video: MongoDBVideos[3] },
							{ title: "backup & restore", img: backup, video: MongoDBVideos[4] },
							{ title: "drop", img: drop, video: MongoDBVideos[5] },
						]
					}
					if (dataItem.id === 5) {
						actions = [
							{ title: "create", img: create, video: PostgreSQLVideos[0] },
							{ title: "HA", img: ha, video: PostgreSQLVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: PostgreSQLVideos[2] },
							{ title: "manage", img: manage, video: PostgreSQLVideos[3] },
							{ title: "backup & restore", img: backup, video: PostgreSQLVideos[4] },
							{ title: "drop", img: drop, video: PostgreSQLVideos[5] },
						]
					}

					if (dataItem.id === 6) {
						actions = [
							{ title: "create", img: create, video: RedisVideos[0] },
							{ title: "HA", img: ha, video: RedisVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: RedisVideos[2] },
							{ title: "manage", img: manage, video: RedisVideos[3] },
							{ title: "backup & restore", img: backup, video: RedisVideos[4] },
							{ title: "drop", img: drop, video: RedisVideos[5] },
						]
					}
					if (dataItem.id === 7) {
						actions = [
							{ title: "create", img: create, video: ElasticsearchVideos[0] },
							
							{ title: "HA", img: ha, video: ElasticsearchVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: ElasticsearchVideos[2] },
							{ title: "manage", img: manage, video: ElasticsearchVideos[3] },
							{ title: "backup & restore", img: backup, video: ElasticsearchVideos[4] },
							{ title: "drop", img: drop, video: ElasticsearchVideos[5] },
						]
					}
					if (dataItem.id === 8) {
						actions = [
							{ title: "create", img: create, video: SQLServerVideos[0] },
							{ title: "HA", img: ha, video: SQLServerVideos[1] },
							{ title: "copy", img: copy, video: OracleVideos[5] },
							{ title: "monitor", img: monitor, video: SQLServerVideos[2] },
							{ title: "manage", img: manage, video: SQLServerVideos[3] },
							{ title: "backup & restore", img: backup, video: SQLServerVideos[4] },
							{ title: "drop", img: drop, video: SQLServerVideos[5] },
						]
					}



					let classes = dataItem.isReverced ? "section section__database section__reverced" : "section section__database";
					let ref;
					if (dataItem.text_id === "Oracle") {
						ref = oracleSectionRef;
					}
					
					else ref = null;
					
					return <DatabaseSection titles={dataItem.titles} subtitles={dataItem.subtitles} key={dataItem.id} bgImg={dataItem.bgImg} classes={classes} description={dataItem.description} delete_menu_id={dataItem.id} text_id={dataItem.text_id} actions={actions} bgvideo={dataItem.bgvideo} handle={handleClick} isBiggest={dataItem.isBiggest} sectionRef={ref} ></DatabaseSection>

				})
			}


			<ContactsForm text_id="contactsForm"></ContactsForm>
			<section id="customers" className="feedback__section">
				<h1 className="feedback__title">Our Customers Feedback​</h1>
				<Carousel value={feedbackItems} itemTemplate={FeedbackItem} showNavigators={false} autoplayInterval={5000} circular className="feedback__slider"></Carousel>
			</section>
			<Footer text_id="Footer" handle={handleClick} scroll={scroll} setScroll={setScroll}></Footer>
			<button ref={toTopBtnRef} className='totopbtn' onClick={() => {
				scroller.scrollTo(SideMenuItems[0].text_id, { smooth: true })
				setScroll({ offset: 0, index: 0 })
				document.getElementsByClassName("side__menu-item--active")[0]?.classList.remove("side__menu-item--active")
				document.getElementsByClassName("side__menu-item")[0]?.classList.add("side__menu-item--active")
				document.querySelector("header").classList.add("header--hidden")
			}}><i className='pi pi-chevron-circle-up'></i></button>
		</div>

	)

}