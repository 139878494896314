import React from 'react';
import './documentation.scss'
import PostgreSQL from './Assets/pg_data';
import Modal from './modal'

function GenerateData({ pgData }) {
  return (
    <div>
      <h2 className='contentTitle'>{pgData.title}</h2>
      {pgData.instructions.map((element, index) => {
        const { text, image } = element;
        const imageUrl = image
        return (
          <div key={index}>
            <p>{text}</p>
            {image && (
              <Modal imageUrl={imageUrl}/>
              // <img src={image} alt={`Step ${index + 1}`} width="50%" />
            )}
          </div>
        );
      })}
    </div>
  );
}

function Docs() {
  return (
    <div>
      <h1>PostgreSQL</h1>
      {PostgreSQL.map((pgData, index) => (
        <div className='mainContainer'>
          <GenerateData key={index} pgData={pgData} />
        </div>
      ))}
    </div>
  );
}

export default Docs;
