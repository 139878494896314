import React from 'react'
import { Card } from 'primereact/card'
import "./infoCard.scss"
import SideMenuItems from '../SideMenuItem/SideMenuItems'
import MenuItem from '../MenuItem/MenuItem'




export default function InfoCard({ title, subtitle, footer, header, id, handle }) {
	return (
		<div id={id} className="card__section" key={"card-section ".concat(id)}>
			<div key={"card".concat(id).concat("bg-block-1")} className="bg">
				
			</div>
			<Card key={"card ".concat(id)} title={title} className="info-card" subtitle={subtitle} footer={footer} header={header}>
				<ul key={title.concat(" list")} onClick={handle}>
					{/* <li key={title.concat("list-item-1")} className="list-disc">A simple drag and drop interface to set up a cluster of your favorite database.</li>
					<li key={title.concat("list-item-2")} className="list-disc">Full REST api for every action.</li>
					<li key={title.concat("list-item-3")} className="list-disc">Full database life cycle, create, set up HA, manage, monitor, upgrade, replicate and more.</li> */}

					{/* 
					<li key={title.concat("list-item-1")} className="list-disc"><Link smooth={true} spy={true} to={SideMenuItems[0].text_id} >Database High Availability</Link></li>
					<li key={title.concat("list-item-2")} className="list-disc"><Link smooth={true} spy={true} to={SideMenuItems[2].text_id}>Full Database Lifecycle</Link></li>

					<li key={title.concat("list-item-3")} className="list-disc"><Link smooth={true} spy={true} to={SideMenuItems[3].text_id}>Drag`n Drop Interface</Link></li>
					<li key={title.concat("list-item-4")} className="list-disc"><Link smooth={true} spy={true} to={SideMenuItems[4].text_id}>Private Database Cloud</Link></li>
					<li key={title.concat("list-item-5")} className="list-disc"><Link smooth={true} spy={true} to={SideMenuItems[5].text_id}>DevOps Ready</Link></li> */}


					{
					//<li key={title.concat("list-item-1")} className="list-disc"><div data-id={SideMenuItems[0].id}>Database High Availability</div></li>
					}
					<li key={title.concat("list-item-2")} className="list-disc"><div data-id={SideMenuItems[0].id}>Full Database Lifecycle</div></li>

					<li key={title.concat("list-item-3")} className="list-disc"><div data-id={SideMenuItems[2].id}>Drag`n Drop Interface</div></li>
					<li key={title.concat("list-item-4")} className="list-disc"><div data-id={SideMenuItems[3].id}>Private Database Cloud</div></li>
					<li key={title.concat("list-item-5")} className="list-disc"><div data-id={SideMenuItems[4].id}>DevOps Ready</div></li>
				</ul>
			</Card>


			{
				<div key={"card".concat(id).concat("bg-block-2")} className="bg">
						<nav className="menu-row">
					<ul key={"menu-row__list"} className="menu-row__list" onClick={handle}>
						{
							SideMenuItems.map((dataItem) => {
								if (dataItem.img) {
									let alt = dataItem.text_id.concat(' logo')
									return (
										<MenuItem img={dataItem.img} key={dataItem.id} alt={alt} text_id={dataItem.text_id} id={dataItem.id}  ></MenuItem>
									)
								}
								return null;
							})
						}
					</ul>
				</nav>
				</div>
			}

		</div>
	)
}
