import placeholder from "../../videos/Completevideo With Voiceover-1.mp4"
import cloud from "../../videos/animations/cloud.mp4"

import cicle from '../../videos/animations/circle3.mp4'

let VideoSections = [


	{

		id: 1,

		titles: ["Full Database Life Cycle"],
		subtitles: ["End to end database management for non administrators"],
		description: [],
		video: cicle,

		text_id: "Cicle",
		isControls: false

	},

	{
		id: 2,
		titles: ["Intuitive Interface"],
		subtitles: ["There are times in life you need to drag (and drop)"],
		description: ["At 123Cluster every action is 1 click or 1 drag`n drop away!"],
		video: placeholder,
		text_id: "intuitive",
		isControls: true,
		normalText: true
	},
	{

		id: 3,

		titles: ["Private Database Cloud"],
		subtitles: ["Cost effective database infrastructure in < 1 hour"],
		description: [],

		video: cloud,
		text_id: "cloud",
		isControls: false


	},

	{
		id: 4,
		titles: ["DEVOPS Ready"],
		subtitles: [
			"Full power of 123Cluster in your DEVOPS infrastructure",
			// "Just extract (once) the REST api url from 123Cluster", 
			// "Change it or just run it as it is from anywhere"
		],
		description: ["Our customers report they are barely using the 123Cluster interface because every action can be done from their DEVOPS tool"],
		video: placeholder,
		text_id: "RestAPI",
		isControls: true
	},

]

export default VideoSections;