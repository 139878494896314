let feedbackItems = [

	{
		id: 1,
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
		sender: "John Doe"
	},
	{
		id: 2,
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
		sender: "John Doe"
	},
	{
		id: 3,
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
		sender: "John Doe"
	},
]

export default feedbackItems;