
import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'


import "../CarouselItem/carouselItem.scss"
import "../../App.scss"



export default function YoutubeButton({ id, video }) {
	const [dialog_visible, setVisible] = useState(false);

	return (
		<>
			<button className="slide__btn" onClick={() => {
				setVisible(true)
				document.body.classList.add("_lock")
			
			}}>
				<i className="icon-youtube"></i>

			</button>
			<Dialog draggable={false} dismissableMask visible={dialog_visible} onHide={() => {
				 setVisible(false)
				 document.body.classList.remove("_lock")
			}} className="dialog">
				<div>
					<video className="video fullscreen" width="100vw" height="100vh" autoPlay
						src={video}></video>
				</div>
			</Dialog>
		</>
	)
}
