import React, { useState } from 'react';
import './modal.scss'; // Import your SCSS file

const Modal = ({ imageUrl }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="image-container">
        <div className='preModal'>
            <img src={imageUrl} alt="Click to Enlarge" onClick={openModal} />
        </div>
      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <img src={imageUrl} alt="Full Size" className="modal-image" />
        </div>
      )}
    </div>
  );
};

export default Modal;
