const constraints = {
    email: {
        presence: {
            message: "Email is required",
            allowEmpty: false
        },
        format: {
            pattern: "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
            message: "Email has invalid format"
        }
    },

    nodesNumber: {
        presence: {
            message: "Number of nodes is required",
            allowEmpty: false
        },
    },
    
}

export default constraints;