import React from 'react'


import "./sidemenuItemLink.scss"


export default function SideMenuItem({ text_id, id, title }) {
	return (
		<div key={id} className="side__menu-item" data-text_id={id} data-id={id}>
			<div className='menu__item-box'><p className='menu__item-text'>{title}</p></div>
		</div>

	)
}
