import oracleBg from "../../videos/animations/girljars.mp4"
import elasticBg from "../../videos/animations/womanxlaptop.mp4"
import mariaBg from "../../videos/animations/database.mp4"
import mongoBg from "../../videos/animations/thinkingbear.mp4"
import redisBg from "../../videos/animations/superwoman.mp4"
import sqlServerBg from "../../videos/animations/waiter.mp4"
import postgresBg from "../../videos/animations/elephant.mp4"
import mysqlBg from "../../videos/animations/delphin.mp4"


const DatabaseSections = [

	{

		id: 1,
		text_id: "Oracle",
		titles: ["123 For Oracle Pluggable Databases - Released!"],
		subtitles: ["Finally, Reach That Cookie Jar!"],
		bgImg: oracleBg,
		description: [
			"You Payed For An Expensive Oracle License",
			"And Still Many Of The Features Are Out Of Reach?"
		],
		isReverced: false,
		isBiggest: false

	},
	{
		id: 2,
		text_id: "MySQL",
		titles: ["123Cluster For MySQL - Release Candidate"],
		subtitles: [""],
		bgImg: mysqlBg,
		//bgvideo: ,
		description: ["We teach MySQL Clusters to play ball"],
		isReverced: true,
		isBiggest: true
	},
	{
		id: 3,
		text_id: "MariaDB",
		titles: ["123Cluster For MariaDB- Release Candidate"],
		subtitles: [""],
		bgImg: mariaBg,
		//bgvideo: ,
		description: ["How many databases can MariaDB handle without one falling?"],
		isReverced: false,
		isBiggest: false

	},
	{
		id: 4,
		text_id: "MongoDB",
		titles: ["123Cluster For MongoDB - Release Candidate"],
		subtitles: [""], 
		//"Hey mister primary where are your secondaries?",
		bgImg: mongoBg,
		//bgvideo: mongo_bg,
		description: [
			"Mongodb's Power Is Its Replication Sets And Sharding Schemes",
			"And Still Most Systems Are Deployed With Only A Single Node"
		],
		isReverced: true,
		isBiggest: false
	},

	{
		id: 5,
		text_id: "PostgreSQL",
		titles: ["123Cluster For PostgreSQL - Release Candidate"],
		subtitles: ["Manage all PostgreSQL Cluster sizes. Big or small"],
		bgImg: postgresBg,
		//bgvideo: pg_bg,
		description: [
			//"Cluster Management Can Be Complex And Out Of Reach For Most Of Us",
			"With 123Cluster You Can Lead The Herd"
		],
		isReverced: false,
		isBiggest: true

	},
	{
		id: 6,
		text_id: "Redis",
		titles: ["123Cluster For Redis - Release Candidate"],
		subtitles:
			[
				//"Redis Clusters are powerful but complex to mange, unless you have 123Cluster",
				"Become a database supergirl",
			],
		bgImg: redisBg,
		//bgvideo: superwoman,
		description: [
			"Redis runs in a single thread per node",
			"Scaling vertically just adds memory",
			"Scaling horizontally adds memory and parallelism",

		],
		isReverced: true,
		isBiggest: true

	},
	{
		id: 7,
		text_id: "SQL-Server",
		titles: ["123Cluster For SQL Server - Release Candidate"],
		subtitles: [""],
		bgImg: sqlServerBg,
		//bgvideo: ,
		description: ["A better way of serving Sql Server"],
		isReverced: false,
		isBiggest: true
	},
	{
		id: 8,
		text_id: "Elasticsearch",
		titles: ["123Cluster For Elasticsearch - Release Candidate"],
		subtitles: [""],
		bgImg: elasticBg,
		//bgvideo: ,
		description: ["An elastic management system for an elastic database"],
		isReverced: false,
		isBiggest: false

	},

]

export default DatabaseSections