import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button } from 'primereact/button' 

import { Dialog } from 'primereact/dialog';
import BuyForm from '../BuyForm/BuyForm';



import { useLocation } from 'react-router-dom';

export default function ProductCard({text_id="", title='', buttonLabel="Buy", description__items=[], span__items=[], price = "0$", moduleName ="", month_left = 12, monthNames = [], passed_name ="", passed_type=""} ) {
	
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const uuid = queryParams.get('uuid')
	const [dialog_visible, setVisible] = useState(false);
	const [email, setEmail] = useState('')
	const [disabled, setDisabled] = useState(true)
	useEffect (() => {
		const json = localStorage.getItem(`${uuid}`)
		if (json) {
		const data = JSON.parse(json)
		
		if (data?.module_type === title && moduleName?.includes(data?.module_name)){
		setEmail(data?.email)
		setVisible(true)
		setDisabled(false)
		
		}
	}
	else{
		console.log("false")
	}

	}, [moduleName, title, uuid])
	let hoverText = ""
	if (month_left <= 0) month_left = 0
	if (month_left === 0){ hoverText = "This price relects the 11 of full months until the end of next year and also 1 free month of subscription"}
	else {hoverText = "This price relects the " + month_left + " of full months until the end of this year and also 1 free month subscription for the first year"}
	return (

	

	<div id = {text_id} className='product__card' key={text_id.concat('_product_card')}>
			<div className='card__bg'>
			<h1 className='card__title'>{title}</h1>
			<div className='price__circle' key={text_id.concat('_product_card_price_circle')}><span key={text_id.concat('_product_card_price')} className='price'>{price}</span>
			
			<div className="price__circle-hover">
			<div className='price__circle-box'>{<p className='price__circle-text'>{hoverText}</p>}</div>
			</div>
			</div>
			
			</div>
			<div className='description__container'>
			<ul className='description__list' key={text_id.concat(" description list")}>
				{
					description__items.map((item, i) => {
						return <li key = {'li'.concat(item)+i}  className='description__item'>{item}</li> 
					})
				}
			</ul>
			{span__items.map((item, i) => {return <span key ={text_id.concat('span__item')+i}className='span__item'>{item}</span>})}
			
			<Button label={buttonLabel} onClick ={() => {
				setVisible(true)
				document.body.classList.add("_lock")
			
			}} ></Button>
			<Dialog draggable={false} visible={dialog_visible} position="center"  onHide={() => {setVisible(false)
			document.body.classList.remove("_lock")
			}}>
				<div>
					<BuyForm month_left = {month_left} type = {title} moduleName = {moduleName} price = {parseFloat(price)} monthNames={monthNames} disabled = {disabled} passed_email= {email}></BuyForm>
				</div>
			</Dialog>
			
			</div>
		</div>
  )
}
