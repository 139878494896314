import "./footer.scss"

import React from 'react'




import { NavLink } from "react-router-dom"
import SideMenuItems from "../SideMenuItem/SideMenuItems"
import { scroller } from "react-scroll"



export default function Footer({ text_id, handle, scroll, setScroll }) {
	return (

		<footer className="footer" id={text_id}>
			<div className="footer__container">
				<div className="footer__wrapper">
					<div className="footer__column">
						<h1 className="title footer__title" >navigation</h1>
						<nav className="nav footer__menu">
							<ul className="footer__menu-list" onClick={handle}>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[5].id} >Oracle pluggable databases</span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[6].id} >Mysql </span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[9].id}>PostgreSQL</span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[8].id}>MongoDB</span></li>
							</ul>
							<ul className="footer__menu-list" onClick={handle}>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[10].id} >Redis</span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[12].id} >ElasticSearch</span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[7].id}>MariaDB</span></li>
								<li className="footer__menu-list-item"><span className="menu__list-link" data-id={SideMenuItems[11].id}>SQLServer</span></li>
							</ul>
						</nav>
					</div>
					<div className="footer__column">
						<h1 className="title footer__title">contacts</h1>
						<nav className="nav footer__menu">
							<ul className="footer__menu-list">
								<li className="footer__menu-list-item">Address : harimon 86 Zofim Israel 44865000</li>
								<li className="footer__menu-list-item">
									<p>Phones :
										<NavLink className="menu__list-link" to="tel:972525188863">(972) 52-51-88-863</NavLink>
									</p>
								</li>
								<li className="footer__menu-list-item">
									<p>Email :
										<NavLink className="menu__list-link" to="mailto:admin@123cluster.com">admin@123cluster.com </NavLink>
										{
										//<NavLink className="menu__list-link" to="mailto:robert@123cluster.com">robert@123cluster.com</NavLink>
									    }
									</p>
								</li>
								{/* <li className="footer__menu-list-item">
									<p>We are open : Mn-Fr : 10am – 8pm</p>
								</li> */}
							</ul>
						</nav>

					</div>
				</div>
				<p className="copyright">All Rights Reserved 2020
					<button className="copyright__link" onClick={() => {
						scroller.scrollTo(SideMenuItems[0].text_id, { smooth: true })
						setScroll({ offset: 0, index: 0 })
						document.getElementsByClassName("side__menu-item--active")[0].classList.remove("side__menu-item--active")
						document.getElementsByClassName("side__menu-item")[0].classList.add("side__menu-item--active")
					}}>123cluster.com</button>
				</p>
			</div>
		</footer>
	)
}
