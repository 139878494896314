
import React, { useState } from 'react';
import "./action.scss";

import { Dialog } from "primereact/dialog";

function Action({ title, img, id, video }) {

	const [dialog_visible, setVisible] = useState(false);

	return (
		<div className="item__link icon-play" href="#" key={id}>
			<img className="action__img " src={img} alt={title + " img"} key={title + " img"} onClick={() => {
				setVisible(true)
				document.body.classList.add("_lock")
				}} /><p>{title}</p>

			<Dialog breakpoints={{ '767px': '100vw' }} visible={dialog_visible} dismissableMask onHide={() => {
				setVisible(false)
				document.body.classList.remove("_lock")
				}} className='action__dialog' key={title + " dialog"}>
				<div key={title + " video wrapper"}>
					<video className="video" controls autoPlay src={video} key={title} width="100vw"></video>
				</div>
			</Dialog>
		</div>
	)
}

export default Action
