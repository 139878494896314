import React from 'react'

export default function FeedbackItem({ text, id, sender }) {
	return (
		<div key={id} className='feedback'>
			<p className='feedback__text'>{text}</p>
			<p className='feedback__sender'>{sender}</p>
		</div>
	)
}
