
import React from "react";

import Home from "./Pages/Home/Home";
import Demos from "./Pages/Demos/Demos";
import Buy from "./Pages/Buy/Buy";
import Doc from "./Pages/Documentation/Documentation.js";

import { Route, Routes } from "react-router-dom";

import "primeflex/primeflex.scss";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "primeicons/primeicons.css";








let handleClick = (e) => {
  console.log(e.target)
}

function App() {

  


  
  return (

    
    <Routes>
      <Route path="/" element={<Home onClick={handleClick} key={"Home"}></Home>}></Route>
      <Route path="/demos/" element={<Demos key={"Demos"}></Demos>} blank></Route>
      <Route path="/buy/" element={<Buy key={"Buy page"}></Buy>} blank></Route>
      <Route path="/docs/" element={<Doc key={"Doc page"}></Doc>} blank></Route>
    
    </Routes>
    
  );
}

export default App;
